import countryData from '@/_components/auth-page/countryData.json';

interface ICountryName {
  [key: string]: string;
}

export type TCountryDataRow = {
  phoneCode: string;
  mask: string;
  countryCode: string;
  name: ICountryName;
  maskNoCode: string;
}

export default class AuthPhoneInputHelper {

  public static getCountryData(): TCountryDataRow[] {
    return countryData.countries;
  }

}
